import React from 'react'
import { P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { RoleSection, RoleTemplate, RoleTitle, RoleUl } from '../RoleTemplate'

const LegalCounsel = (): React.JSX.Element => (
  <RoleTemplate
    roleName="Legal Counsel, Wills & Probate"
    roleUrl="https://dignity.kallidusrecruit.com/vacancyinformation.aspx?vguid=ee745e03-a214-493a-b321-cbd00f0c83ca"
    location="Maidenhead (Hybrid – 3 days on-site, 2 days from home)"
    salary="£50,000 - £60,000"
    contractType="Full-time, Permanent"
    closingDate="25th April 2025"
    content={
      <>
        <RoleSection margin={[GTR.M, 0]}>
          <RoleTitle>The role</RoleTitle>
          <P>
            Ready to shape the future of legal services? Join one of
            Europe&apos;s most innovative consumer legal tech companies as Legal
            Counsel and play a key role in delivering industry-leading Wills and
            Probate solutions as part of our high-performing, newly established
            legal team.
          </P>
          <P>
            You&apos;ll report directly to our Head of Legal and build a close
            partnership with our Wills and Probate teams. Occasionally, you will
            be called upon to support other areas of the legal services
            function.
          </P>
          <P>
            As Legal Counsel, you will lead on the legal oversight of
            Farewill&apos;s professional executorship service and probate
            offering, while also ensuring robust quality assurance across our
            Wills product.
          </P>
        </RoleSection>
        <RoleSection>
          <RoleTitle>Key Responsibilities</RoleTitle>
          <P>
            <i>Professional executorship duties</i>
          </P>
          <RoleUl>
            <li>
              Where Farewill Trustees is appointed as professional executor
              under a will, you will lead on carrying out our professional
              duties
            </li>
            <li>
              In collaboration with our Head of Legal, you will contribute to
              the commercial strategy for Trustees work, including the
              commercial model and partnering with charities on their executor
              services
            </li>
          </RoleUl>

          <P>
            <i>Product design</i>
          </P>
          <RoleUl>
            <li>
              Provide legal input into our Probate product quote flows, landing
              pages, product guides, marketing materials, call scripts and more
            </li>
          </RoleUl>

          <P>
            <i>Quality of service</i>
          </P>
          <RoleUl>
            <li>
              Train our Probate Sales team on topics such as estate
              administration, valuing estates and regulatory changes so that
              they are well-equipped to talk to customers about our product
            </li>
            <li>
              Be the day-to-day point of escalation for questions/issues related
              to Probate from across the business
            </li>
            <li>
              Provide quality assurance by checking wills written by our Will
              writing specialists for accuracy and compliance with legal
              requirements
            </li>
            <li>
              Dealing with Larke v Nugus requests and other queries related to
              disputed wills
            </li>
            <li>
              Work closely with our Head of Legal to continuously identify,
              manage, and mitigate risk across the business
            </li>
          </RoleUl>
        </RoleSection>
        <RoleSection>
          <RoleTitle>What We&apos;re Looking For</RoleTitle>
          <P>
            We&apos;re seeking a qualified Solicitor (England & Wales), ideally
            STEP-certified, with 3–6+ years&apos; experience in probate. A
            strong command of tax and trusts is essential, with additional
            knowledge of Northern Irish and Scottish wills being a plus.
            You&apos;ll be pragmatic, commercially astute, and solutions-driven,
            with exceptional communication skills. If this sounds like you,
            we&apos;d love to hear from you.
          </P>
        </RoleSection>
        <RoleSection>
          <RoleTitle>What We Offer</RoleTitle>
          <RoleUl>
            <li>Annual salary of £50,000 - £60,000 depending on experience</li>
            <li>Upto 10% Bonus Scheme</li>
            <li>25 Days Holiday + Bank Holidays</li>
            <li>X2 Life Assurance</li>
            <li>Pension Scheme</li>
            <li>Car Allowance</li>
            <li>Hybrid flexible working (3 days in our Maidenhead office)</li>
            <li>Personal development and mentorship opportunities</li>
            <li>
              A chance to make a meaningful contribution to people&apos;s lives
              and death
            </li>
          </RoleUl>
        </RoleSection>
        <RoleSection>
          <RoleTitle>What are the next steps?</RoleTitle>
          <P>
            To be considered for this role, please submit your application via
            the &apos;apply&apos; button, where a member of our Talent Team will
            review your application.
          </P>
        </RoleSection>
        <RoleSection>
          <RoleTitle>FCA Regulation Statement</RoleTitle>
          <P>
            Please note that this role is subject to meeting regulatory
            requirements. As an FCA-regulated organisation, we are committed to
            adopting the highest standards and we do this by employing
            individuals with the appropriate skills, experience and integrity to
            protect both the company and our clients. If you are considered for
            a role, you will be providing your permission for us to conduct the
            relevant checks and assessments that will be carried out during the
            recruitment and onboarding process. The nature of the checks will
            depend on whether the role is subject to the Senior Managers and
            Certification Regime (SMCR).
          </P>
          <P>
            Please note in accordance with our pre-employment checks, all roles
            will require a criminal record check and further enhanced checks may
            apply. Certain roles will be subject to ongoing checks in line with
            the Group SMCR Framework (Fit & Proper Policy). Further details will
            be explained to you throughout the recruitment process.
          </P>
        </RoleSection>
        <RoleSection>
          <RoleTitle>Equality, Diversity and Inclusion Statement</RoleTitle>
          <P>
            Our Vision is to connect with our clients, colleagues, company, and
            communities based on our principles whilst celebrating our
            differences.
          </P>
          <P>
            We aim to empower leaders and colleagues to seek out and understand
            new voices and perspectives; to be vocal about inequity and
            exclusion, and to take decisive, informed, and bold action to
            promote inclusion and belonging.
          </P>
          <P>
            Any unsolicited submissions from agencies will be accepted as a
            direct application from the candidate and no fees will be payable.
          </P>
          <P>
            We reserve the right depending on application numbers to close or
            extend the closing dates for positions; we would therefore recommend
            an early application.
          </P>
        </RoleSection>
      </>
    }
  />
)

export default LegalCounsel
